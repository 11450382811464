<template>
    <form ref="form" @submit.prevent="save">
        <fieldset :disabled="!theProduct.manually_created">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ $t("products.attributes.sku") }}
                    </div>
                </div>
                <input
                    type="text"
                    class="form-control"
                    required
                    name="sku"
                    v-model="theProduct.sku"
                />
            </div>

            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ $t("products.attributes.url") }}
                    </div>
                </div>
                <input
                    type="url"
                    class="form-control"
                    placeholder="http(s)://.."
                    name="url"
                    v-model="theProduct.url"
                />
            </div>
        </fieldset>

        <hr />

        <div v-if="theProduct.alternative_product" class="input-group mb-3">
            <div class="input-group-prepend">
                <div class="input-group-text">
                    {{ $t("products.attributes.alternative_product") }}
                </div>
            </div>
            <input
                type="text"
                class="form-control"
                disabled
                :value="theProduct.alternative_product.name"
            />
            <button
                type="button"
                class="btn btn-secondary"
                @click.prevent="theProduct.alternative_product = null"
            >
                <i class="fal fa-trash"></i>
            </button>
            <input
                type="hidden"
                name="alternative_product_id"
                :value="theProduct.alternative_product.id"
            />
        </div>
        <input
            type="text"
            class="form-control"
            :required="theProduct.allows_user_assigned_price"
            v-model="theProduct.minimum_price_including_vat"
        />

        <div
            class="input-group mb-3"
            v-show="!theProduct.allows_user_assigned_price"
        >
            <div class="input-group-prepend">
                <div class="input-group-text">
                    {{ $t("products.attributes.price_including_vat") }}
                </div>
            </div>
            <input
                type="text"
                class="form-control"
                :required="!theProduct.allows_user_assigned_price"
                v-model="theProduct.price_including_vat"
            />
            {{ successMessage }}
        </div>
        <div v-if="errorMessage" class="alert alert-danger mb-3" role="alert">
            {{ errorMessage }}
        </div>

        <div v-if="!saving" class="d-flex justify-content-between">
            <button type="submit" class="btn btn-primary">
                {{
                    $t(
                        theProduct.id
                            ? "products.editor.button_save_existing"
                            : "products.editor.button_save_new"
                    )
                }}
            </button>
            <button
                type="button"
                class="btn btn-secondary"
                @click="$emit('cancel')"
            >
                Stäng / Avbryt
            </button>
        </div>
        <b-spinner v-else></b-spinner>
    </form>
</template>

<style lang="scss" scoped>
.is-deleted-product {
    opacity: 0.5;
}
</style>

<script>
import axios from "axios";

const PRICE_CLEANUP_REGEXP = /[^0-9\.]/g;

export default {
    props: {
        product: { type: Object, required: true },
        products: { type: Array, required: true },
    },
    data() {
        return {
            theProduct: null,
            saving: false,
            errorMessage: null,
            successMessage: null,
        };
    },
    computed: {
        optionsForAlternativeProduct() {
            return this.products
                .filter(
                    (prod) =>
                        prod.id &&
                        prod.sku != this.theProduct.sku &&
                        !prod.solo_product
                )
                .map((prod) => ({ id: prod.id, name: prod.name }));
        },
    },
    watch: {
        product: {
            handler() {
                if (
                    this.product?.id !== "_new" &&
                    JSON.stringify(this.product) ===
                        JSON.stringify(this.theProduct)
                ) {
                    return;
                }

                if (this.product?.id === "_new") {
                    this.createNewProduct();
                } else {
                    this.theProduct = JSON.parse(JSON.stringify(this.product));
                }

                this.errorMessage = null;
                this.successMessage = null;

                if (this.$refs.form) {
                    this.$refs.form
                        .querySelectorAll('input[type="file"]')
                        .forEach((i) => (i.value = null));
                }
            },
            immediate: true,
        },
        "theProduct.sku"() {
            if (!this.$refs.form) {
                return;
            }

            const skuInput = this.$refs.form.querySelector('input[name="sku"]');
            if (
                this.theProduct.sku &&
                this.products.filter(
                    (p) =>
                        p !== this.theProduct &&
                        p.sku.toLowerCase() ===
                            this.theProduct.sku.toLowerCase()
                ).length > 0
            ) {
                skuInput.setCustomValidity(
                    this.$t("products.editor.sku_in_use_error")
                );
            } else {
                skuInput.setCustomValidity("");
            }
        },
        "theProduct.allows_user_assigned_price"(newVal) {
            if (!newVal) {
                return;
            }

            this.theProduct.price_including_vat = 0;
            this.theProduct.minimum_price_including_vat = 0;
            this.theProduct.input_amount = false;
            this.theProduct.input_prompt = null;
            this.theProduct.alternative_product = null;
        },
        "theProduct.price_including_vat"(newValue) {
            if (this.theProduct.manually_created && newValue) {
                const cleanupValue = newValue
                    .replaceAll(",", ".")
                    .replaceAll(PRICE_CLEANUP_REGEXP, "");
                if (newValue !== cleanupValue) {
                    this.theProduct.price_including_vat = cleanupValue;
                }
            }
        },
        "theProduct.minimum_price_including_vat"(newValue) {
            if (this.theProduct.manually_created && newValue) {
                const cleanupValue = newValue
                    .replaceAll(",", ".")
                    .replaceAll(PRICE_CLEANUP_REGEXP, "");
                if (newValue !== cleanupValue) {
                    this.theProduct.minimum_price_including_vat = cleanupValue;
                }
            }
        },
    },
    methods: {
        createNewProduct() {
            this.theProduct = {
                id: null,
                sku: null,
                url: null,
                name: null,
                description_title: null,
                description: null,
                image_url: null,
                categories: [],
                allows_user_assigned_price: false,
                price_including_vat: null,
                minimum_price_including_vat: null,
                price_unit: "st",
                currency: "sek_kr",
                input_amount: true,
                input_prompt: null,
                color: null,
                pattern: null,
                material: null,
                age_group: null,
                gender: null,
                size: null,
                in_stock: true,
                deleted: false,
                manually_created: true,
                is_recurring: false,
            };
        },
        async save() {
            if (this.saving) {
                return;
            }

            this.saving = true;
            this.errorMessage = null;
            this.successMessage = null;

            try {
                const formData = new FormData(this.$refs.form);
                // tiptap-field
                if (this.theProduct.description) {
                    formData.append("description", this.theProduct.description);
                } else {
                    this.errorMessage = "Beskrivning måste fyllas in";
                    this.saving = false;
                    return;
                }

                if (this.theProduct.id) {
                    formData.append("_method", "put");
                    const response = await axios.post(
                        this.route("products.update", this.theProduct.id),
                        formData
                    );

                    this.$emit("updated", response.data);
                } else {
                    const response = await axios.post(
                        this.route("products.store"),
                        formData
                    );
                    this.$emit("created", response.data);
                }

                this.successMessage = this.$t("products.editor.save_success");
            } catch (e) {
                if (console.warn) {
                    console.warn(e);
                }

                this.errorMessage = this.$t("products.editor.save_error");
            }

            this.saving = false;
        },
    },
};
</script>
