<template>
    <data-analysis-quotes-selector
        @filterConfig="onFilterConfig"
        :enableQuotesTable="false"
    >
        <template #header>
            <div class="col-md-12 mb-3 row no-gutters">
                <div class="col"></div>
                <div
                    class="col-3 d-flex justify-content-end"
                    v-if="Object.keys(filterConfig).length !== 0 && theSurveyId"
                >
                    <button class="btn btn-primary mr-2" @click="exportToExcel">
                        <i class="fal fa-file-excel"></i> Exportera
                    </button>
                    <button
                        class="btn btn-primary"
                        data-target="#filter-wrapper"
                        data-toggle="collapse"
                    >
                        <i class="fal fa-filter"></i> Filter
                    </button>
                </div>
            </div>
        </template>

        <template #after-filters>
            <div class="card">
                <template v-if="theSurveyId && !theSurvey">
                    <div class="card-header d-flex justify-content-between">
                        Laddar..
                        <b-button
                            variant="secondary"
                            size="sm"
                            @click.prevent="closeSurvey"
                            >Avbryt</b-button
                        >
                    </div>
                    <div class="card-body">
                        <b-spinner></b-spinner>
                    </div>
                </template>
                <template v-else-if="theSurvey">
                    <div class="card-header d-flex justify-content-between">
                        Enkät: {{ theSurvey.name }}
                        <b-button
                            variant="secondary"
                            size="sm"
                            @click.prevent="closeSurvey"
                            >Stäng</b-button
                        >
                    </div>
                    <div class="card-body">
                        <div class="d-block overflow-auto">
                            <b-table
                                striped
                                hover
                                v-show="!loadingTheSurvey"
                                :fields="theSurveyFields"
                                :items="theSurveyItems"
                                sort-by="completed_at"
                                :sort-desc="true"
                                :label-sort-asc="null"
                                :label-sort-desc="null"
                                :label-sort-clear="null"
                            >
                                <template #head()="{ label }">
                                    <div class="text-nowrap">{{ label }}</div>
                                </template>

                                <template #cell()="{ value }">
                                    <ul
                                        v-if="Array.isArray(value)"
                                        class="list-unstyled"
                                    >
                                        <li
                                            v-for="(vVal, vIdx) in value"
                                            :key="vIdx"
                                        >
                                            {{ vVal }}
                                        </li>
                                    </ul>
                                    <template v-else>{{ value }}</template>
                                </template>

                                <template
                                    #cell(quote.client_company)="{ value }"
                                >
                                    <small
                                        v-if="value === 'Privatkund'"
                                        style="opacity: 0.5"
                                    >
                                        {{ value }}
                                    </small>
                                    <small v-else>
                                        {{ value }}
                                    </small>
                                </template>

                                <template #cell(quote.tags)="data">
                                    <b-badge
                                        v-for="(tagItem, tagIdx) in data.value"
                                        :key="tagIdx"
                                        class="ml-1"
                                        pill
                                        variant="secondary"
                                    >
                                        {{ tagItem }}
                                    </b-badge>
                                </template>

                                <template #cell(created_at)="{ value }">
                                    {{ value.substr(0, 10) }}
                                </template>

                                <template #cell(quote_id)="{ value }">
                                    <b-button
                                        class="mt-1"
                                        variant="primary"
                                        size="sm"
                                        :href="route('quotes.edit', value)"
                                    >
                                        Kundkort
                                    </b-button>
                                </template>
                            </b-table>

                            <div
                                class="mt-3 d-flex flex-column justify-content-center align-items-center"
                            >
                                <b-spinner
                                    v-show="loadingTheSurvey"
                                    label="Laddar.."
                                ></b-spinner>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="card-header d-flex justify-content-between">
                        Enkäter
                    </div>
                    <div class="card-body">
                        <b-spinner v-if="loadingSurveys"></b-spinner>
                        <ul
                            v-else-if="surveys && surveys.length > 0"
                            class="d-flex flex-column justify-content-center mb-3 pl-0"
                        >
                            <li
                                v-for="survey in surveys"
                                :key="survey.id"
                                class="d-flex justify-content-between align-items-center mb-3"
                            >
                                <div>
                                    <h6 class="my-0 font-weight-bold">
                                        {{ survey.name }}
                                    </h6>
                                    <small
                                        >{{ survey.replies_count }} svar</small
                                    >
                                </div>
                                <div>
                                    <b-button
                                        class="mt-1"
                                        variant="primary"
                                        size="sm"
                                        @click.prevent="
                                            onSelectSurvey(survey.id)
                                        "
                                        >Visa svar</b-button
                                    >
                                </div>
                            </li>
                        </ul>
                        <p v-else>
                            Det finns inga enkäter, men du kan
                            <a :href="route('surveys.index')">skapa en här</a>
                        </p>
                    </div>
                </template>
            </div>
        </template>
    </data-analysis-quotes-selector>
</template>

<style scoped lang="scss">
.scroll-x {
    width: 100%;
    overflow-x: auto;
    margin: 1px;
    padding: 1px;
}
</style>

<script>
import axios from "axios";
export default {
    components: {},
    data() {
        return {
            loadingSurveys: false,
            loadingTheSurvey: false,
            surveys: null,
            theSurvey: null,
            theSurveyId: null,
            filterConfig: {},
            excelExportUrl: null,
        };
    },
    computed: {
        theSurveyFields() {
            if (!this.theSurveyId || !this.theSurvey) {
                return;
            }

            const knownUuids = this.theSurvey.questions.map(
                (question) => question.uuid
            );
            const headers = [];
            headers.push({
                key: "completed_at",
                label: "Enkät insamlad",
                formatter: (value, _key, _quote) =>
                    value ? value.substring(0, 10) : "-",
                sortable: true,
            });

            this.theSurvey.questions.forEach((question) => {
                headers.push({
                    key: question.uuid,
                    label: `Fråga: ${question.prompt}`,
                    sortable: true,
                });
            });

            this.theSurvey.completed_replies.forEach((reply) => {
                reply.answers.forEach((answer) => {
                    if (!knownUuids.includes(answer.question.uuid)) {
                        knownUuids.push(answer.question.uuid);
                        headers.push({
                            key: answer.question.uuid,
                            label: answer.question.prompt,
                            sortable: true,
                        });
                    }
                });
            });

            headers.push(
                {
                    key: "quote.created_at",
                    label: "Erbjudande skapat",
                    formatter: (value, _key, _quote) =>
                        value ? value.substring(0, 10) : "-",
                    sortable: true,
                },
                {
                    key: "quote.customer_name",
                    label: "Kund",
                    sortable: true,
                },
                {
                    key: "quote.client_company",
                    label: "Bolagsnamn",
                    sortable: true,
                    formatter: (value, _key, _quote) =>
                        value ? value : "Privatkund",
                    sortByFormatted: true,
                },
                {
                    key: "quote.primary_signature_at",
                    label: "Signeringsdatum",
                    sortable: true,
                    sortDirection: "desc",
                    formatter: (value, _key, _quote) =>
                        value ? value.substring(0, 10) : "-",
                    sortByFormatted: true,
                },
                {
                    key: "quote.seller",
                    label: "Säljare",
                    sortable: true,
                    formatter: (value, _key, _quote) =>
                        value ? value.name : "-",
                    sortByFormatted: true,
                },
                {
                    key: "quote.tags",
                    label: "Taggar",
                    sortable: true,
                    sortByFormatted: (value, _key, _item) => value.join(", "),
                },
                {
                    key: "quote_id",
                    label: "",
                }
            );

            return headers;
        },
        theSurveyItems() {
            if (!this.theSurveyId || !this.theSurvey) {
                return;
            }

            return this.theSurvey.completed_replies.map((reply) => {
                return reply.answers.reduce(
                    (row, answer) => {
                        row[answer.question.uuid] = answer.formatted_value;
                        return row;
                    },
                    {
                        created_at: reply.created_at,
                        quote_id: reply.quote_id,
                        quote: reply.quote,
                        completed_at: reply.completed_at,
                        id: reply.id,
                    }
                );
            });
        },
    },
    async mounted() {
        await this.loadSurveys();
    },
    methods: {
        async loadSurveys() {
            this.loadingSurveys = true;
            try {
                const surveys = [];
                let nextUrl = this.route("surveys.index");
                while (nextUrl) {
                    let response = await axios.get(nextUrl);
                    surveys.push(...response.data.data);
                    nextUrl = response.data.links.next;
                }
                this.surveys = surveys.sort((a, b) =>
                    a.name.localeCompare(b.name)
                );
            } finally {
                this.loadingSurveys = false;
            }
        },
        async onSelectSurvey(surveyId) {
            this.loadingTheSurvey = true;
            this.theSurveyId = surveyId;

            let { data: surveyData } = await axios.get(
                this.route("survey_data.show", surveyId)
            );
            const quotes = await this.loadQuotes(surveyId);

            if (this.theSurveyId === surveyData?.id) {
                surveyData.completed_replies =
                    surveyData.completed_replies.filter((cr) => {
                        return quotes[cr.quote_id] !== undefined;
                    });
                surveyData.completed_replies.forEach((cr) => {
                    cr.quote = quotes[cr.quote_id];
                });
                this.theSurvey = surveyData;
            } else {
                // The user has selected another survey while we were loading this one
            }
            this.loadingTheSurvey = false;
        },
        async loadQuotes(surveyId) {
            let quotes = {};

            let params = { _query: { completed_survey_id: surveyId } };
            if (Object.keys(this.filterConfig).length !== 0) {
                params["_query"]["filter_config"] = this.filterConfig;
            }
            let nextUrl = route("data_analysis.quotes.index", params);
            while (nextUrl) {
                const resp = await axios.get(nextUrl);
                nextUrl = resp.data.links.next;
                resp.data.data.forEach((quote) => {
                    quotes[quote.id] = quote;
                });
            }

            return quotes;
        },
        closeSurvey() {
            this.theSurveyId = null;
            this.theSurvey = null;
        },
        onFilterConfig(filterConfig) {
            this.filterConfig = filterConfig;
            if (this.theSurveyId) {
                this.onSelectSurvey(this.theSurveyId);
            }
        },
        async exportToExcel() {
            const url = route("survey_data.export.excel", this.theSurveyId);
            const payload = {
                fields: this.theSurveyFields.map((field) => {
                    return { label: field.label, key: field.key };
                }),
                items: this.theSurveyItems,
            };

            const response = await axios.post(url, payload, {
                responseType: "blob",
            });
            let fileName = "Enkat.xlsx"; // default file name if no header is present
            const contentDisposition = response.headers["content-disposition"];
            if (
                contentDisposition &&
                contentDisposition.indexOf("attachment") !== -1
            ) {
                const fileNameMatch =
                    contentDisposition.match(/filename="?(.+)"?/);
                if (fileNameMatch.length === 2) {
                    fileName = fileNameMatch[1];
                }
            }

            const downloadUrl = window.URL.createObjectURL(
                new Blob([response.data])
            );
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", fileName); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
    },
};
</script>
